@import 'spinner';
@import 'backdrop';
@import 'toast';
@import 'tooltip';
@import 'button';
@import 'dialog';
@import 'tables';
@import 'input';
@import 'tabs';
@import 'angular-editor';
@import '~@angular/cdk/overlay-prebuilt.css';
@import 'mat-field';
:root {
  --primary-color: #2c5283;
  --primary-color-transparent: #2c5283cc;

  --secondary-color-1: #2c9299;
  --secondary-color-2: #52afe3;
  --secondary-color-3: #8bd0f0;
  --secondary-color-4: #e2f4fc;
  --secondary-color-5: #e8eef1;

  --neutral-color-1: #ffffff;
  --neutral-color-2: #f7f8f8;
  --neutral-color-3: #eff1ff;
  --neutral-color-4: #e4eaea;
  --neutral-color-5: #c4cbd3;
  --neutral-color-6: #97aca9;
  --neutral-color-7: #495752;
  --neutral-color-8: #333333;
  --neutral-color-9: #88a7c2;
  --neutral-color-10: #707070;
  --neutral-color-11: #f2f5f5;
  --neutral-color-12: #ced8d5;
  --neutral-color-13: #434343;
  --neutral-transparent-color-8: #33333366;

  --action-danger-color: #de3a3a;
  --action-danger-transparent-color: #de3a3a1a;
  --action-info-color: #3c81bb;
  --action-info-transparent-color: #3c81bb1a;
  --action-warning-color: #ebb915;
  --action-warning-color-2: #f3eacc;
  --action-warning-transparent-color: #ebba151a;
  --action-success-color: #2bc457;
  --action-success-color-2: #89bb97;
  --action-success-transparent-color: #2bc4571a;
  --action-light-green-color: #b1d448;
  --action-light-green-transparent-color: #b1d44834;
  --action-accent-color: #ad69d7;
  --action-accent-color-2: #c2aecf;
  --action-accent-transparent-color: #ad69d71a;

  --complementary-color: #2cc5a9;

  --navbar-height: 50px;
  --sidebar-width: 56px;
  --sidebar-full-width: 256px;
  --sidebar-background-color: #2c5283e6;

  --primary-font: 'Lato';
  --secondary-font: 'Roboto';
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

* {
  box-sizing: border-box;
  font-family: var(--primary-font), 'Helvetica Neue', sans-serif;
  line-height: normal;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--neutral-color-2);
  border-radius: 10px;
}

.align-left {
  text-align: left !important;
}

.align-center {
  text-align: center !important;
}

.align-right {
  text-align: right !important;
}

//estilos para botones
.btn {
  outline: none;
  font-size: 13px;
  padding: 10px 19px;
  border: none;
  border-radius: 18px;
  color: var(--action-info-color);
  cursor: pointer;

  &.btn-primary {
    background-color: var(--neutral-color-4);

    &:hover {
      background-color: var(--neutral-color-2);
      color: var(--secondary-color-2);
    }
  }

  &.btn-secondary {
    background-color: var(--neutral-color-2);

    &:hover {
      color: var(--secondary-color-2);
    }
  }

  &.btn-tertiary {
    background-color: var(--action-info-color);
    color: var(--neutral-color-1);
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: var(--secondary-color-2);
    }
  }

  &.btn-fourth {
    background-color: var(--neutral-color-1);
    color: var(--action-info-color);

    &:hover {
      color: var(--secondary-color-2);
    }
  }

  &.btn-warning {
    background-color: var(--neutral-color-1);
    color: var(--action-warning-color);
  }

  &.btn-warning-fill {
    background-color: var(--action-warning-color);
    color: var(--neutral-color-1);
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: var(--neutral-color-2);
      color: var(--action-warning-color);
    }
  }

  &.btn-disabled {
    cursor: not-allowed;
    background-color: var(--neutral-color-4);
    color: var(--neutral-color-6);

    &:hover {
      background-color: var(--neutral-color-4);
      color: var(--neutral-color-6);
    }
  }
}

.timepicker-overlay {
  z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1100 !important;
}

//input check
.input-check-custom {
  cursor: pointer;
  background-color: var(--neutral-color-1);
  width: 16px;
  height: 16px;
  appearance: none;
  border-radius: 4px;
  border: 1px solid var(--neutral-color-6);
}

.input-check-custom:checked {
  background-color: var(--action-info-color);
  border: none;
  background-image: url('../src/assets/svg/check-seleccionado.svg');
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.grecaptcha-badge {
  visibility: hidden;
}

.login-page .grecaptcha-badge {
  visibility: visible !important;
  z-index: 2;
}
