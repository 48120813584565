$check-seleccionado-svg: url("../svg/check-seleccionado.svg");
.input-custom-style {
  height: 40px;
  width: 100%;
  border-radius: 8px;
  color: var(--neutral-color-7);
  border: solid 1px var(--neutral-color-5);
  outline: none;
  padding-left: 17.5px;
  padding-right: 17.5px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  font-family: var(--primary-font), "Helvetica Neue", sans-serif;
  font-weight: normal;

  font-stretch: normal;

  font-style: normal;
}

.input-custom-style:hover {
  border: solid 1px var(--secondary-color-1);
}
.input-custom-style.disabled {
  border: solid 1px var(--neutral-color-5);
}

.input-custom-style:focus {
  border: solid 1px var(--secondary-color-1);
}

.input-custom-style-error {
  border: solid 1px var(--action-danger-color);
}

.input-custom-style::placeholder {
  color: var(--neutral-color-7);
}

.input-custom-check-svg {
  cursor: pointer;
  background-color: var(--neutral-color-1);
  width: 16px;
  height: 16px;
  appearance: none;
  border-radius: 4px;
  border: 1px solid var(--neutral-color-6);
}

.input-custom-check-svg:checked {
  background-color: var(--action-info-color);
  border: none;
  background-image: url("../../assets/svg/check-seleccionado.svg");
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

//select

.input-select-custom {
  position: relative;
  input {
    @extend .input-custom-style;
    @extend .text-ellipsis-custom-style;
  }
  .input-custom-style-error {
    border: solid 1px var(--action-danger-color);
  }
  mat-icon {
    position: absolute;
    right: 10px;
    top: 8px;
    color: var(--neutral-color-5);
    transition: transform 0.5s ease;
    &.rotate-icon-select-custom {
      transform: rotate(180deg);
    }
  }
}

.options-custom-container {
  background-color: var(--neutral-color-1);
  border-radius: 8px;
  color: var(--neutral-color-6);
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  .option-custom-item {
    height: 24px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    @extend .text-ellipsis-custom-style;
    &:hover {
      background-color: var(--secondary-color-4);
      color: var(--secondary-color-1);
    }
  }
}

.text-ellipsis-custom-style {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.number-phone-form-field {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mat-mdc-form-field-infix {
    display: flex !important;
    align-items: center !important;
  }
}

//checkbox
.custom-input-check {
  cursor: pointer;
  background-color: var(--neutral-color-1);
  width: 16px;
  height: 16px;
  appearance: none;
  border-radius: 4px;
  border: 1px solid var(--neutral-color-6);
}

.custom-input-check:checked {
  background-color: var(--action-info-color);
  border: none;
  background-image: $check-seleccionado-svg;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
