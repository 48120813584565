.backdrop {
  background: rgba(228, 234, 234, 0);
  backdrop-filter: blur(1.5px);
}

.backdrop-padding-sugerencias {
  background: rgba(228, 234, 234, 0);
  backdrop-filter: blur(1.5px);
  padding: 16.33% 32.72%;
}

.overlay-panel-habilitacion {
  padding: 5.5% 31.4%;
  width: 100%;
  height: 100%;
}

.overlay-panel-chat {
  width: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.panel-class-spinner {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: transparent;
    box-shadow: none;
  }
}

.backdrop-spinner {
  background-color: green;
}

.overlay-transparent-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
