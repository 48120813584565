.custom-tooltip div {
  background-color: var(--secondary-color-2) !important;
  color: var(--neutral-color-1) !important;
  font-size: 12px !important;
  font-family: var(--primary-font), "Helvetica Neue", sans-serif !important;
  line-height: 1.3 !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  padding: 8px !important;
  border-radius: 8px !important;
  top: 0 !important;
}

.custom-tooltip-danger div {
  background-color: var(--action-danger-color) !important;
  color: var(--neutral-color-1) !important;
  font-size: 12px !important;
  font-family: var(--primary-font), "Helvetica Neue", sans-serif !important;
  line-height: 1.3 !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  white-space: nowrap !important;
  padding: 8px !important;
  border-radius: 8px !important;
  top: 0 !important;
  max-width: unset !important;
}

.custom-tooltip-warning div {
  background-color: var(--action-warning-color) !important;
  color: var(--neutral-color-1) !important;
  font-size: 12px !important;
  font-family: var(--primary-font), "Helvetica Neue", sans-serif !important;
  line-height: 1.3 !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  padding: 8px !important;
  border-radius: 8px !important;
  top: 0 !important;
  max-width: unset !important;
}

.custom-tooltip-success div {
  background-color: var(--action-success-color) !important;
  color: var(--neutral-color-1) !important;
  font-size: 12px !important;
  font-family: var(--primary-font), "Helvetica Neue", sans-serif !important;
  line-height: 1.3 !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  white-space: nowrap !important;
  padding: 8px !important;
  border-radius: 8px !important;
  top: 0 !important;
}

.custom-tooltip-warning div {
  background-color: var(--action-warning-color) !important;
  color: var(--neutral-color-1) !important;
  font-size: 12px !important;
  font-family: var(--primary-font), "Helvetica Neue", sans-serif !important;
  line-height: 1.3 !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  white-space: nowrap !important;
  padding: 8px !important;
  border-radius: 8px !important;
  top: 0 !important;
}

.custom-tooltip-nowrap div {
  background-color: var(--secondary-color-2) !important;
  color: var(--neutral-color-1) !important;
  font-size: 12px !important;
  font-family: var(--primary-font), "Helvetica Neue", sans-serif !important;
  line-height: 1.3 !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  padding: 8px !important;
  border-radius: 8px !important;
  top: 0 !important;
  white-space: nowrap;
  max-width: max-content;
}
