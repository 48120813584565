.btn-dialog-v1 {
  @extend .button-styles-custom;
  background-color: #3c81bb;
  color: white;
  &:hover {
    background-color: #52afe3;
  }
}

.btn-dialog-v2 {
  cursor: pointer;
  @extend .font-button;
  text-align: center;
  font-size: 13px;
  outline: none;
  height: 36px;
  width: 100%;
  border: none;
  border-radius: 25px;
  background-color: white;
  color: #3c81bb;
  &:hover {
    color: #52afe3;
  }
}

.btn-dialog-v3 {
  @extend .button-styles-custom;
  background-color: var(--action-warning-color);
  color: white;
  &:hover {
    background-color: var(--neutral-color-1);
    color: var(--action-warning-color);
  }
}

.btn-dailog-disabled {
  background-color: #e4eaea;
  color: #97aca9;
  pointer-events: none;
}
.font-button {
  font-family: var(--primary-font), "Helvetica Neue", sans-serif;
}

.button-styles-custom {
  cursor: pointer;
  @extend .font-button;
  text-align: center;
  font-size: 13px;
  outline: none;
  height: 36px;
  width: 100%;
  border: none;
  border-radius: 25px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
}

//class include disabled/hover

button.btn-dialog-v6 {
  @extend .button-styles-custom;
  background-color: #3c81bb;
  color: white;
  &:hover:not(:disabled) {
    background-color: #52afe3;
  }
  &:disabled {
    background-color: #e4eaea;
    color: #97aca9;
    pointer-events: none;
  }
  &.disabled-color {
    background-color: #e4eaea;
    color: #97aca9;
    &:hover {
      background-color: #e4eaea;
      color: #97aca9;
    }
  }
}

button.btn-dialog-v8 {
  @extend .button-styles-custom;
  background-color: var(--action-danger-color);
  color: white;
  &:hover:not(:disabled) {
    background-color: var(--neutral-color-1);
    color: var(--action-danger-color);
  }
  &:disabled {
    background-color: #e4eaea;
    color: #97aca9;
    pointer-events: none;
  }
}

.btn-dialog-v7 {
  @extend .button-styles-custom;
  background-color: var(--action-warning-color);
  color: white;
  &:hover:not(:disabled) {
    background-color: var(--neutral-color-1);
    color: var(--action-warning-color);
  }
  &:disabled {
    background-color: #e4eaea;
    color: #97aca9;
    pointer-events: none;
  }
}

button.btn-dialog-v9 {
  cursor: pointer;
  @extend .font-button;
  text-align: center;
  font-size: 13px;
  outline: none;
  height: 21px;
  width: 150px;
  border: none;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #3c81bb;
  color: white;
  &:hover:not(:disabled) {
    background-color: #52afe3;
  }
  &:disabled {
    background-color: #e4eaea;
    color: #97aca9;
    pointer-events: none;
  }
  &.disabled-color {
    background-color: #e4eaea;
    color: #97aca9;
    &:hover {
      background-color: #e4eaea;
      color: #97aca9;
    }
  }
}
