.container-component .mdc-tab__text-label {
  font-family: var(--secondary-font), "Helvetica Neue", sans-serif !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #495752 !important;
}

.container-component
  .mat-mdc-tab.mdc-tab--active:hover
  .mdc-tab-indicator__content--underline {
  border-color: none !important;
  z-index: 1 !important;
}

.container-component .mat-mdc-tab-list {
  border-bottom: 0.5px solid #97aca9;
}

.container-component .mdc-tab--active .mdc-tab__text-label,
.mdc-tab--active .mdc-tab__icon {
  color: #3c81bb !important;
  transition: 0ms !important;
}

.container-component .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border: none !important;
}

.container-component mat-tab-group {
  z-index: 1 !important;
}

//tab-custom
// .container-mat-tab .mat-mdc-tab-group {
//   height: 100%;
// }
// .container-mat-tab
//   .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs
//   > .mat-mdc-tab-header
//   .mat-mdc-tab {
//   align-items: center;
//   //gap: 10px;
//   &:hover {
//     background-color: #ffffff !important;
//   }
// }

// .container-mat-tab .mat-mdc-tab-list {
//   flex-grow: 0;
// }

// .container-mat-tab .mdc-tab {
//   padding-left: 0;
//   padding-right: 0;
// }

// .container-mat-tab .mat-mdc-tab .mdc-tab-indicator__content--underline {
//   border-color: #2c5283 !important;
// }
// .container-mat-tab .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
//   color: #ffffff !important;
//   background-color: #2c5283 !important;
//   border-radius: 100px;
// }

// .container-mat-tab .mat-mdc-tab .mdc-tab__text-label {
//   color: #2c5283 !important ;
//   height: 30px !important;
//   padding: 13px !important;
// }

// .container-mat-tab .mat-ripple {
//   &:hover {
//     background-color: transparent !important;
//   }
// }

// .container-mat-tab .mat-mdc-tab-labels {
//   gap: 10px;
// }

// ::ng-deep .mat-tab-label:hover {
//   background-color: transparent !important;
// }
