//toast styles
.custom-toast {
  .mat-mdc-snack-bar-label {
    padding: 0 !important;
  }
  .mdc-snackbar__surface {
    padding: 0 !important;
    min-width: unset !important;
    max-height: 90%;
  }
  .mat-mdc-snack-bar-container {
    min-width: unset !important;
  }
}
