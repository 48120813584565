.font-label-table-custom {
  font-family: var(--secondary-font), "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #495752;
}

.custom-table-styles {
  display: flex;
  flex-direction: column;
  min-height: 0;
  .custom-labels-container {
    display: flex;
    flex-shrink: 0;
  }
  .custom-table-container-row {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;
    .custom-row {
      display: flex;
    }
  }
}

.custom-labels-style {
  height: 35px;
  flex-shrink: 0;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.custom-row-style {
  background-color: var(--neutral-color-1);
  border-radius: 8px;
  height: 60px;
  flex-shrink: 0;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.custom-font-basic-label {
  font-family: var(--secondary-font), "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--neutral-color-7);
}

.custom-font-basic-row {
  font-family: var(--primary-font), "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--neutral-color-7);
}

.no-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
