//font-custom
.font-family-lato {
  font-family: var(--primary-font), "Helvetica Neue", sans-serif;
}

.font-family-robot {
  font-family: var(--secondary-font), "Helvetica Neue", sans-serif;
}

.tittle-custom-dialog {
  @extend .font-family-robot;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #495752;
}

.sub-tittle-custom-dialog {
  @extend .font-family-robot;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #495752;
}

.text-custom-dialog {
  @extend .font-family-robot;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #495752;
}

//dialog-styles
.body-container-dialog {
  height: 100%;
  width: 100%;
  max-height: 100%;
  min-height: 0;
  background-color: var(--neutral-color-1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0;

  .tittle-dialog {
    @extend .tittle-custom-dialog;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    padding-left: 15px;
    padding-right: 8px;
    mat-icon {
      cursor: pointer;
      border-radius: 100%;
      &:hover {
        background-color: var(--action-danger-color);
        color: var(--neutral-color-1);
      }
    }
  }

  .body-card-container-dialog {
    flex-grow: 1;
    background-color: var(--secondary-color-4);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px;
    height: 100%;

    min-height: 0;
    .card {
      background-color: var(--neutral-color-1);
      flex-grow: 1;
      height: 100%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 17px;
      border-radius: 8px;
      overflow: auto;
      .tittle-card {
        display: flex;
        align-items: center;
        height: 47.8px;
        border-bottom: solid 0.5px #e4eaea;
      }
    }
  }

  .contacts-dialog {
    min-height: 25vh;
    max-height: 40vh;
    .card {
      max-height: 35vh;
    }
  }

  .bottom-dialog {
    flex-shrink: 0;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    .btn-container {
      flex-shrink: 0;
      padding-bottom: 20px;
      padding-right: 15px;
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      > button {
        width: 74px !important;
      }
    }
  }
}

//Dialog warning
.body-card-container-dialog-warning {
  padding-top: 8px;
  padding-bottom: 36px;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 0;
  height: 100%;
  width: 100%;
  background-color: var(--neutral-color-1);
  display: flex;
  flex-direction: column;
  .tittle-custom-dialog-warning {
    display: flex;
    justify-content: flex-end;
    height: 24px;
    mat-icon {
      cursor: pointer;
      border-radius: 100%;
      color: var(--neutral-color-7);
      &:hover {
        color: var(--neutral-color-1);
        background-color: var(--action-danger-color);
      }
    }
  }
  .custom-dialog-warning-icon {
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    mat-icon {
      font-size: 50px;
      color: var(--action-warning-color);
      height: 50px;
      width: 50px;
    }
  }
  .custom-dialog-warning-body {
    flex-grow: 1;
  }
  .button-container-warning {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      display: flex;
      gap: 12px;
    }
  }
}
