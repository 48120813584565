.filed-container {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__trailing {
    border-color: var(--neutral-color-6) !important;
  }

  /* Change the border width */
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline
    .mdc-notched-outline__trailing {
    border-width: 0.5px !important;
  }

  /* Change the border focused color */
  .mdc-text-field--outlined:not(
      .mdc-text-field--disabled
    ).mdc-text-field--focused
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(
      .mdc-text-field--disabled
    ).mdc-text-field--focused
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(
      .mdc-text-field--disabled
    ).mdc-text-field--focused
    .mdc-notched-outline__trailing {
    border-color: var(--action-info-color) !important;
  }

  /* Cambiar el color del borde en estado de error */
  .mat-form-field-invalid
    .mdc-text-field--outlined
    .mdc-notched-outline__leading,
  .mat-form-field-invalid .mdc-text-field--outlined .mdc-notched-outline__notch,
  .mat-form-field-invalid
    .mdc-text-field--outlined
    .mdc-notched-outline__trailing {
    border-color: var(--action-danger-color) !important;
  }

  /* Mantener el color rojo del borde durante el hover en estado de error */
  .mat-form-field-invalid:not(.mat-focused):hover
    .mdc-text-field--outlined
    .mdc-notched-outline__leading,
  .mat-form-field-invalid:not(.mat-focused):hover
    .mdc-text-field--outlined
    .mdc-notched-outline__notch,
  .mat-form-field-invalid:not(.mat-focused):hover
    .mdc-text-field--outlined
    .mdc-notched-outline__trailing {
    border-color: var(--action-danger-color) !important;
  }

  /* Mantener el color rojo del borde cuando está enfocado y en estado de error */
  .mat-form-field-invalid.mat-focused
    .mdc-text-field--outlined
    .mdc-notched-outline__leading,
  .mat-form-field-invalid.mat-focused
    .mdc-text-field--outlined
    .mdc-notched-outline__notch,
  .mat-form-field-invalid.mat-focused
    .mdc-text-field--outlined
    .mdc-notched-outline__trailing {
    border-color: var(--action-danger-color) !important;
  }

  .mdc-notched-outline__notch {
    border-left: none !important;
  }
}
