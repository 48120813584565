.text-container .angular-editor-toolbar[_ngcontent-ng-c834769704] {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.text-container
  .angular-editor[_ngcontent-ng-c1917172166]
  .angular-editor-wrapper[_ngcontent-ng-c1917172166]
  .angular-editor-textarea[_ngcontent-ng-c1917172166] {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-top: 0;
  height: 100px;
  &:focus {
    outline: none;
  }
}
